@use '@angular/material' as mat;
@use 'abstracts/variables';
// temporary import to pull in the new font style //refactor
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$custom-typography: mat.define-typography-config(
  $font-family: 'Manrope',
);

@include mat.all-component-typographies($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$wtax-primary-palette: /* For use in src/lib/core/theming/_palette.scss */ (
  50: #eaf9f6,
  100: #caf0e9,
  200: #a6e6da,
  300: #82dbcb,
  400: #68d4bf,
  500: #4dccb4,
  600: #46c7ad,
  700: #3dc0a4,
  800: #34b99c,
  900: #25ad8c,
  A100: #ebfffa,
  A200: #b8ffed,
  A400: #85ffe0,
  A700: #6bffd9,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

$wtax-primary: mat.define-palette($wtax-primary-palette);
$wtax-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$wtax-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$wtax-theme: mat.define-light-theme(
  (
    color: (
      primary: $wtax-primary,
      accent: $wtax-accent,
      warn: $wtax-warn,
    ),
  )
);

$wtax-private-wealth-primary-palette: (
  50: #efe6f4,
  100: #d6c1e4,
  200: #bc98d3,
  300: #a36ec2,
  400: #904cb4,
  500: #7d29a7,
  600: #7524a2,
  700: #691a99,
  800: #5f1291,
  900: #4e0084,
  A100: #ebfffa,
  A200: #b8ffed,
  A400: #85ffe0,
  A700: #6bffd9,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);
$wtax-private-wealth-primary: mat.define-palette($wtax-private-wealth-primary-palette);
$wtax-private-wealth-theme: mat.define-light-theme(
  (
    color: (
      primary: $wtax-private-wealth-primary,
      accent: $wtax-accent,
      warn: $wtax-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.radio-theme($wtax-theme);
// @include mat-button-theme($wtax-theme);
// @include mat-core-theme($wtax-theme);
// @include mat-autocomplete-theme($wtax-theme);
// @include mat-badge-theme($wtax-theme);
// @include mat-bottom-sheet-theme($wtax-theme);
// @include mat-button-toggle-theme($wtax-theme);
// @include mat-card-theme($wtax-theme);
@include mat.checkbox-theme($wtax-theme);
// @include mat-chips-theme($wtax-theme);
// @include mat-table-theme($wtax-theme);

@include mat.datepicker-theme($wtax-theme);

.wtax-private-wealth-theme {
  @include mat.datepicker-theme($wtax-private-wealth-theme);
}

// @include mat-dialog-theme($wtax-theme);
// @include mat-divider-theme($wtax-theme);
// @include mat-expansion-panel-theme($wtax-theme);
// @include mat-form-field-theme($wtax-theme);
// @include mat-grid-list-theme($wtax-theme);
// @include mat-icon-theme($wtax-theme);
// @include mat-input-theme($wtax-theme);
// @include mat-list-theme($wtax-theme);
// @include mat-menu-theme($wtax-theme);
// @include mat-paginator-theme($wtax-theme);
// @include mat-progress-bar-theme($wtax-theme);
// @include mat-progress-spinner-theme($wtax-theme);
// @include mat-radio-theme($wtax-theme);
// @include mat-select-theme($wtax-theme);
// @include mat-sidenav-theme($wtax-theme);
@include mat.slide-toggle-theme($wtax-theme);
// @include mat-slider-theme($wtax-theme);
// @include mat-stepper-theme($wtax-theme);
// @include mat-sort-theme($wtax-theme);
@include mat.tabs-theme($wtax-theme);
// @include mat-toolbar-theme($wtax-theme);
// @include mat-tooltip-theme($wtax-theme);
// @include mat-tree-theme($wtax-theme);
// @include mat-snack-bar-theme($wtax-theme);

// New design Features
.new-font-style {
  $custom-typography: mat.define-typography-config(
    $font-family: 'Montserrat',
  );

  * {
    font-family: 'Montserrat', sans-serif;
  }

  h1 {
    color: map-get(variables.$wtax-green-dark, 100);
  }

  font-weight: map-get(variables.$font-weights, 'medium');
  letter-spacing: 0.038em;

  .new-header-color {
    color: map-get(variables.$wtax-green-dark, 100);
  }

  .new-tab-style {
    .mat-tab-label .mat-tab-label-content {
      justify-content: flex-start;
      padding-left: 0.8rem;
    }
  }
}

.private-wealth-color {
  color: map-get(variables.$wtax-private-wealth, 100);
}

.private-wealth-background-lighter {
  background-color: map-get(variables.$wtax-private-wealth, 50);
}

.private-wealth-background {
  background-color: map-get(variables.$wtax-private-wealth, 100);
}

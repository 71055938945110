@use 'abstracts/variables';
@use 'abstracts/functions';

.private-wealth-radio-group {
  .mat-radio-button {
    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      height: 24px;
      width: 24px;
    }

    .mat-radio-outer-circle {
      background-color: #fff;
      border-color: map-get(variables.$wtax-private-wealth, 50);
      border-width: 1px;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-width: 2px;
      }
    }

    /*disabled*/
    &.mat-radio-disabled:not(.mat-radio-checked) .mat-radio-outer-circle {
      background-color: map-get(variables.$wtax-private-wealth, 50);
      border-color: map-get(variables.$wtax-private-wealth, 50);
      border-width: 1px;
    }
    &.mat-radio-checked.mat-radio-disabled {
      .mat-radio-outer-circle {
        border-color: map-get(variables.$wtax-private-wealth, 50);
        border-width: 2px;
      }
      .mat-radio-inner-circle {
        background-color: map-get(variables.$wtax-private-wealth, 50);
      }
    }
  }

  .mat-radio-button.mat-primary .mat-radio-inner-circle,
  .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: map-get(variables.$wtax-private-wealth, 100);
  }

  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: map-get(variables.$wtax-private-wealth, 100);
  }

  &.private-wealth-radio-group {
    column-gap: functions.spacing(4);
    display: inline-grid;
    grid-auto-flow: column;
  }
}

@use 'abstracts/variables';

@each $name, $colors in variables.$color-map {
  @each $variant, $value in $colors {
    .color-#{'' + $name}-#{$variant} {
      color: $value;
    }

    .background-#{'' + $name}-#{$variant} {
      background-color: $value;
    }
  }
}

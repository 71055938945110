@use 'abstracts/variables';
@use 'abstracts/functions';

/**
 * WTax style override for Material tabs. Use this class on <mat-tab-group>.
 */
.wtax-tabs {
  --label-padding: 16px;
  --border-radius: 16px;

  .mat-focus-indicator {
    color: map-get(variables.$wtax-black, 100);
  }

  .mat-tab-header {
    border-bottom: none;
    overflow: unset; // So we can use box-shadow on the active element properly.
  }

  .mat-tab-label-container {
    overflow: unset; // So we can use box-shadow on the active element properly.
  }

  .mat-tab-label,
  .mat-tab-link {
    background-color: map-get(variables.$wtax-blue-dark, 10);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    color: map-get(variables.$wtax-black, 100);
    height: unset;
    opacity: 1;
    padding: var(--label-padding) var(--label-padding) calc(var(--label-padding) + var(--border-radius));
    transition: all 0.125s;

    &:not(:first-child) {
      margin-left: 3px;
    }
  }

  .mat-tab-link {
    margin-bottom: -1px;
  }

  .mat-tab-label-active {
    background-color: #fff;
    box-shadow: 0 4px 24px rgba(91, 98, 121, 0.14);
    font-weight: map-get(variables.$font-weights, 'extra-bold');
    z-index: 1;

    &:not(:first-child)::before {
      background-image: radial-gradient(circle at 0 0, transparent var(--border-radius), #fff var(--border-radius));
      bottom: var(--border-radius);
      content: '';
      height: var(--border-radius);
      position: absolute;
      right: 100%;
      width: var(--border-radius);
    }

    &::after {
      background-image: radial-gradient(circle at 100% 0, transparent var(--border-radius), #fff var(--border-radius));
      bottom: var(--border-radius);
      content: '';
      height: var(--border-radius);
      left: 100%;
      position: absolute;
      width: var(--border-radius);
    }

    &.mat-ripple {
      overflow: visible !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .mat-tab-label-content {
    position: relative; // So we can position the badge properly.
    width: 100%;
  }

  .mat-tab-body,
  .mat-tab-body .mat-tab-body-content {
    overflow: visible !important; /* stylelint-disable-line declaration-no-important */
  }

  .mat-ripple-element {
    display: none; // Because of the overrides on overflows in this file, ripple would look bad on this component.
  }

  .tab-badge,
  .tab-completeness-indicator {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .mat-ink-bar {
    background-color: map-get(variables.$wtax-green, 100);
    bottom: var(--border-radius);
    clip-path: polygon(
      var(--label-padding) 0,
      calc(100% - 16px) 0,
      calc(100% - 16px) 100%,
      var(--label-padding) 100%
    ); // Ink bar's width is calculated somewhere internally in Material. This seems to be the only way to reduce its width.
    height: 3px;
    z-index: 1;
  }

  .mat-tab-body-wrapper {
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 24px 0 rgba(91, 98, 121, 0.1);
    margin-top: calc(-1 * var(--border-radius));
    z-index: 1; // So it overlaps the box-shadow coming from the active label.
  }

  .mat-tab-body {
    padding: functions.spacing(5);
  }
}

.tabs-full-width-labels {
  .mat-tab-label {
    flex: 1;
    font-size: 90%;
  }

  .mat-tab-label-active {
    &:last-child::after {
      display: none;
    }
  }
}

.tab-badge {
  align-items: center;
  background-color: map-get(variables.$wtax-state, 'warning');
  border-radius: 8px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  min-height: 24px;
  min-width: 24px;
  padding: 0 functions.spacing(0.5);
}

mat-tab.no-body-padding {
  .mat-tab-body {
    padding: 0;
  }
}

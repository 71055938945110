@use 'abstracts/functions';

$sizes: (
  '0': 0,
  '0-5': functions.spacing(0.5),
  '1': functions.spacing(1),
  '1-5': functions.spacing(1.5),
  '2': functions.spacing(2),
  '3': functions.spacing(3),
  '4': functions.spacing(4),
  '5': functions.spacing(5),
  '6': functions.spacing(6),
  '7': functions.spacing(7),
  '8': functions.spacing(8),
  '9': functions.spacing(9),
);

$variations: (
  'm': 'margin',
  'mt': 'margin-top',
  'mr': 'margin-right',
  'mb': 'margin-bottom',
  'ml': 'margin-left',
  'p': 'padding',
  'pt': 'padding-top',
  'pr': 'padding-right',
  'pb': 'padding-bottom',
  'pl': 'padding-left',
);

@each $variationClassName, $property in $variations {
  @each $sizeClassName, $value in $sizes {
    .#{$variationClassName}-#{$sizeClassName} {
      #{$property}: #{$value} !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

@each $sizeClassName, $value in $sizes {
  .mx-#{$sizeClassName} {
    margin-left: #{$value} !important; /* stylelint-disable-line declaration-no-important */
    margin-right: #{$value} !important; /* stylelint-disable-line declaration-no-important */
  }

  .my-#{$sizeClassName} {
    margin-bottom: #{$value} !important; /* stylelint-disable-line declaration-no-important */
    margin-top: #{$value} !important; /* stylelint-disable-line declaration-no-important */
  }

  .px-#{$sizeClassName} {
    padding-left: #{$value} !important; /* stylelint-disable-line declaration-no-important */
    padding-right: #{$value} !important; /* stylelint-disable-line declaration-no-important */
  }

  .py-#{$sizeClassName} {
    padding-bottom: #{$value} !important; /* stylelint-disable-line declaration-no-important */
    padding-top: #{$value} !important; /* stylelint-disable-line declaration-no-important */
  }
}

@each $sizeClassName, $value in $sizes {
  .border-radius-#{$sizeClassName} {
    border-radius: #{$value};
  }
}

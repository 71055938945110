@use 'abstracts/variables';
@use 'abstracts/functions';

$white: map-get(variables.$wtax-white, 100);
$green100: map-get(variables.$wtax-green, 100);
$green80: map-get(variables.$wtax-green, 80);
$red100: map-get(variables.$wtax-red, 100);
$red80: map-get(variables.$wtax-red, 80);
$dark10: map-get(variables.$wtax-blue-dark, 10);
$dark40: map-get(variables.$wtax-blue-dark, 40);
$custodian100: map-get(variables.$wtax-custodian, 100);
$custodian80: map-get(variables.$wtax-custodian, 80);
$custodian60: map-get(variables.$wtax-custodian, 60);
$private-wealth100: map-get(variables.$wtax-private-wealth, 100);
$private-wealth50: map-get(variables.$wtax-private-wealth, 50);
$private-wealth25: map-get(variables.$wtax-private-wealth, 25);

.button {
  align-items: center;
  background-color: $green100;
  border: none;
  border-radius: 24px;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: map-get(variables.$font-weights, 'extra-bold');
  justify-content: center;
  line-height: 1.5;
  padding: functions.spacing(1) functions.spacing(4);

  svg-icon {
    &:first-of-type {
      margin-left: functions.spacing(0.5);
    }

    &:last-of-type {
      margin-right: functions.spacing(0.5);
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $green80;
  }

  &:disabled {
    background-color: $dark10;
    color: $dark40;
    cursor: unset;
  }

  &.button-red {
    background-color: $red100;

    &:hover {
      background-color: $red80;
    }

    &:disabled {
      background-color: $dark10;
    }
  }

  &.hover-dark:disabled {
    background-color: $dark40;
    color: $white;
  }

  .mat-ripple-element {
    background-color: rgba($color: #fff, $alpha: 0.3) !important; /* stylelint-disable-line declaration-no-important */
  }
}

.button.button-secondary {
  background-color: $white;
  border: 1px solid $green100;
  color: $green100;

  &:hover {
    border: 1px solid $green80;
    color: $green80;
  }

  .mat-ripple-element {
    background-color: rgba($color: $green100, $alpha: 0.3) !important; /* stylelint-disable-line declaration-no-important */
  }

  &.button-red {
    border: 1px solid $red100;
    color: $red100;

    &:not(:disabled):hover {
      background-color: $white;
      border: 1px solid $red80;
      color: $red80;
    }

    .mat-ripple-element {
      background-color: rgba($color: $red100, $alpha: 0.3) !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $dark40;
    color: $dark40;
  }
}

.button.button-tertiary {
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: $green100;
  padding: 0;

  &:hover {
    background-color: transparent;
    color: $green80;
  }

  &.button-red {
    color: $red100;

    &:not(:disabled):hover {
      color: $red80;
    }
  }

  &:disabled {
    color: $dark40;
  }
}

.button-mini {
  font-size: 14px;
  line-height: 16px;
}

.button.custodian {
  background-color: $custodian100;

  &:hover {
    background-color: $custodian80;
  }

  &:disabled {
    background-color: $dark40;
    color: $white;
  }

  .mat-ripple-element {
    background-color: rgba($color: $custodian80, $alpha: 0.3) !important; /* stylelint-disable-line declaration-no-important */
  }
}

.button.private-wealth {
  background-color: $private-wealth100;

  &:hover {
    background-color: $private-wealth50;
  }

  &:disabled {
    background-color: $private-wealth25;
    color: $white;
  }

  .mat-ripple-element {
    background-color: rgb($color: $private-wealth50, $alpha: 0.3) !important; /* stylelint-disable-line declaration-no-important */
  }
}

.button.private-wealth-secondary {
  background-color: $white;
  border: 1px solid $private-wealth100;
  color: $private-wealth100;

  &:hover {
    border: 1px solid $private-wealth50;
    color: $private-wealth50;
  }

  .mat-ripple-element {
    background-color: rgba($color: $green100, $alpha: 0.3) !important; /* stylelint-disable-line declaration-no-important */
  }
}

.button.private-wealth-tertiary {
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: $private-wealth100;
  padding: 0;

  &:hover {
    background-color: transparent;
    color: $private-wealth50;
  }

  &.button-red {
    color: $red100;

    &:not(:disabled):hover {
      color: $red80;
    }
  }

  &:disabled {
    color: $dark40;
  }
}

.button.private-wealth-mini {
  background-color: $private-wealth100;
  color: $white;
  font-size: 14px;
  line-height: 16px;
}
.button.button-secondary.custodian {
  background-color: $white;
  border: 1px solid $custodian100;
  color: $custodian100;

  &:hover {
    border: 1px solid $custodian80;
    color: $custodian80;
  }

  .mat-ripple-element {
    background-color: rgba($color: $custodian80, $alpha: 0.3) !important; /* stylelint-disable-line declaration-no-important */
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $dark40;
    color: $dark40;
  }
}

@use 'abstracts/variables';

html,
body {
  height: 100%;
}

body {
  font-family: 'Manrope', 'Helvetica Neue', sans-serif;
  font-size: map-get(map-get(variables.$paragraphs, 'regular'), 'font-size');
  font-weight: map-get(variables.$font-weights, 'normal');
  line-height: map-get(map-get(variables.$paragraphs, 'regular'), 'line-height');
  margin: 0;
}

svg-icon {
  // svg icon is inline-block by default, but that makes svg inside it misaligned
  // so inline-flex fixes that
  display: inline-flex !important; /* stylelint-disable-line declaration-no-important */
  // @ngneat/svg-icon uses font-size: 1.5rem by default,
  // but we want icons to pick their size from their current context instead.
  font-size: 1em !important; /* stylelint-disable-line declaration-no-important */
}

.wtax-link {
  color: map-get(variables.$wtax-green, 100);
  text-decoration: none;

  &:hover {
    color: map-get(variables.$wtax-green, 100);
    text-decoration: none;
  }
}

.clickable {
  cursor: pointer;
}

.cdk-global-scrollblock-prevent-offset {
  top: 0 !important; // stylelint-disable-line
  position: initial !important; // stylelint-disable-line
  overflow: hidden !important; // stylelint-disable-line
}

.text-input-appearance {
  -moz-appearance: textfield; // stylelint-disable-line

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; // stylelint-disable-line
    margin: 0;
  }
}

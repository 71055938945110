@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

html {
  --mat-badge-text-font: Manrope;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Manrope;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Manrope;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Manrope;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Manrope;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Manrope;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Manrope;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  font-family: Manrope;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Manrope;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Manrope;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 96px;
  font-family: Manrope;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
  font-family: Manrope;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
  font-family: Manrope;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Manrope;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Manrope;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-font: Manrope;
  --mat-standard-button-toggle-text-font: Manrope;
}

html {
  --mat-datepicker-calendar-text-font: Manrope;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Manrope;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Manrope;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Manrope;
  --mat-stepper-header-label-text-font: Manrope;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Manrope;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

.mat-tree {
  font-family: Manrope;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

html {
  --mat-option-label-text-font: Manrope;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Manrope;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

.mat-mdc-card {
  --mat-card-title-text-font: Manrope;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Manrope;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Manrope;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Manrope;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Manrope;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Manrope;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Manrope;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Manrope;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Manrope;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Manrope;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Manrope;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Manrope;
  --mat-slide-toggle-label-text-size: 14px;
  --mat-slide-toggle-label-text-tracking: 0.0178571429em;
  --mat-slide-toggle-label-text-line-height: 20px;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Manrope));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Manrope;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Manrope;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Manrope;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Manrope;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Manrope;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Manrope;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Manrope;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Manrope;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Manrope));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Manrope));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Manrope));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Manrope;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Manrope;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Manrope;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Manrope;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Manrope;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #4dccb4;
  --mdc-radio-selected-hover-icon-color: #4dccb4;
  --mdc-radio-selected-icon-color: #4dccb4;
  --mdc-radio-selected-pressed-icon-color: #4dccb4;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #4dccb4;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #4dccb4;
  --mdc-checkbox-selected-hover-icon-color: #4dccb4;
  --mdc-checkbox-selected-icon-color: #4dccb4;
  --mdc-checkbox-selected-pressed-icon-color: #4dccb4;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4dccb4;
  --mdc-checkbox-selected-hover-state-layer-color: #4dccb4;
  --mdc-checkbox-selected-pressed-state-layer-color: #4dccb4;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #4dccb4;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(77, 204, 180, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(77, 204, 180, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(77, 204, 180, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #4dccb4;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(77, 204, 180, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.wtax-private-wealth-theme {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #7d29a7;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(125, 41, 167, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(125, 41, 167, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(125, 41, 167, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #7d29a7;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(125, 41, 167, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.wtax-private-wealth-theme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.wtax-private-wealth-theme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.wtax-private-wealth-theme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.wtax-private-wealth-theme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
.wtax-private-wealth-theme .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.wtax-private-wealth-theme .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #46c7ad;
  --mdc-switch-selected-handle-color: #46c7ad;
  --mdc-switch-selected-hover-state-layer-color: #46c7ad;
  --mdc-switch-selected-pressed-state-layer-color: #46c7ad;
  --mdc-switch-selected-focus-handle-color: #25ad8c;
  --mdc-switch-selected-hover-handle-color: #25ad8c;
  --mdc-switch-selected-pressed-handle-color: #25ad8c;
  --mdc-switch-selected-focus-track-color: #82dbcb;
  --mdc-switch-selected-hover-track-color: #82dbcb;
  --mdc-switch-selected-pressed-track-color: #82dbcb;
  --mdc-switch-selected-track-color: #82dbcb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #4dccb4;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #4dccb4;
  --mat-tab-header-active-ripple-color: #4dccb4;
  --mat-tab-header-inactive-ripple-color: #4dccb4;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #4dccb4;
  --mat-tab-header-active-hover-label-text-color: #4dccb4;
  --mat-tab-header-active-focus-indicator-color: #4dccb4;
  --mat-tab-header-active-hover-indicator-color: #4dccb4;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #4dccb4;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.new-font-style {
  font-weight: 500;
  letter-spacing: 0.038em;
}
.new-font-style * {
  font-family: "Montserrat", sans-serif;
}
.new-font-style h1 {
  color: #2a7580;
}
.new-font-style .new-header-color {
  color: #2a7580;
}
.new-font-style .new-tab-style .mat-tab-label .mat-tab-label-content {
  justify-content: flex-start;
  padding-left: 0.8rem;
}

.private-wealth-color {
  color: #6b3d86;
}

.private-wealth-background-lighter {
  background-color: #ceb4dd;
}

.private-wealth-background {
  background-color: #6b3d86;
}

/*!
 * Bootstrap Reboot v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Manrope", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

svg-icon {
  display: inline-flex !important; /* stylelint-disable-line declaration-no-important */
  font-size: 1em !important; /* stylelint-disable-line declaration-no-important */
}

.wtax-link {
  color: #4dccb4;
  text-decoration: none;
}
.wtax-link:hover {
  color: #4dccb4;
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.cdk-global-scrollblock-prevent-offset {
  top: 0 !important;
  position: initial !important;
  overflow: hidden !important;
}

.text-input-appearance {
  -moz-appearance: textfield;
}
.text-input-appearance::-webkit-outer-spin-button, .text-input-appearance::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.color-green-100 {
  color: #4dccb4;
}

.background-green-100 {
  background-color: #4dccb4;
}

.color-green-80 {
  color: #71d6c3;
}

.background-green-80 {
  background-color: #71d6c3;
}

.color-green-60 {
  color: #94e0d2;
}

.background-green-60 {
  background-color: #94e0d2;
}

.color-green-40 {
  color: #b8ebe1;
}

.background-green-40 {
  background-color: #b8ebe1;
}

.color-green-20 {
  color: #d9f3ee;
}

.background-green-20 {
  background-color: #d9f3ee;
}

.color-green-10 {
  color: #edfaf7;
}

.background-green-10 {
  background-color: #edfaf7;
}

.color-green-secondary-20 {
  color: #e3f6e8;
}

.background-green-secondary-20 {
  background-color: #e3f6e8;
}

.color-green-secondary-10 {
  color: #f1fbf4;
}

.background-green-secondary-10 {
  background-color: #f1fbf4;
}

.color-green-dark-100 {
  color: #2a7580;
}

.background-green-dark-100 {
  background-color: #2a7580;
}

.color-red-100 {
  color: #fb6a72;
}

.background-red-100 {
  background-color: #fb6a72;
}

.color-red-80 {
  color: #fc888e;
}

.background-red-80 {
  background-color: #fc888e;
}

.color-red-60 {
  color: #fda6aa;
}

.background-red-60 {
  background-color: #fda6aa;
}

.color-red-20 {
  color: #fee1e3;
}

.background-red-20 {
  background-color: #fee1e3;
}

.color-red-10 {
  color: #fff0f1;
}

.background-red-10 {
  background-color: #fff0f1;
}

.color-orange-60 {
  color: #ffcb8e;
}

.background-orange-60 {
  background-color: #ffcb8e;
}

.color-orange-20 {
  color: #ffeed9;
}

.background-orange-20 {
  background-color: #ffeed9;
}

.color-orange-10 {
  color: #fff6ec;
}

.background-orange-10 {
  background-color: #fff6ec;
}

.color-blue-10 {
  color: #edf8ff;
}

.background-blue-10 {
  background-color: #edf8ff;
}

.color-black-100 {
  color: #0e1118;
}

.background-black-100 {
  background-color: #0e1118;
}

.color-black-80 {
  color: #3e4146;
}

.background-black-80 {
  background-color: #3e4146;
}

.color-black-60 {
  color: #6e7074;
}

.background-black-60 {
  background-color: #6e7074;
}

.color-white-100 {
  color: #fff;
}

.background-white-100 {
  background-color: #fff;
}

.color-blue-dark-100 {
  color: #5b6279;
}

.background-blue-dark-100 {
  background-color: #5b6279;
}

.color-blue-dark-80 {
  color: #7c8194;
}

.background-blue-dark-80 {
  background-color: #7c8194;
}

.color-blue-dark-60 {
  color: #9da1af;
}

.background-blue-dark-60 {
  background-color: #9da1af;
}

.color-blue-dark-40 {
  color: #c0c3cc;
}

.background-blue-dark-40 {
  background-color: #c0c3cc;
}

.color-blue-dark-20 {
  color: #dee0e4;
}

.background-blue-dark-20 {
  background-color: #dee0e4;
}

.color-blue-dark-10 {
  color: #efeff2;
}

.background-blue-dark-10 {
  background-color: #efeff2;
}

.color-blue-dark-3 {
  color: #fafafb;
}

.background-blue-dark-3 {
  background-color: #fafafb;
}

.color-state-success {
  color: #72d28d;
}

.background-state-success {
  background-color: #72d28d;
}

.color-state-error {
  color: #fb6a72;
}

.background-state-error {
  background-color: #fb6a72;
}

.color-state-warning {
  color: #ffa842;
}

.background-state-warning {
  background-color: #ffa842;
}

.color-state-info {
  color: #4cbbfa;
}

.background-state-info {
  background-color: #4cbbfa;
}

.color-state-attention {
  color: #d60303;
}

.background-state-attention {
  background-color: #d60303;
}

.color-custodian-100 {
  color: #3a5074;
}

.background-custodian-100 {
  background-color: #3a5074;
}

.color-custodian-80 {
  color: #5d82b2;
}

.background-custodian-80 {
  background-color: #5d82b2;
}

.color-custodian-60 {
  color: #61738f;
}

.background-custodian-60 {
  background-color: #61738f;
}

.color-custodian-40 {
  color: #cbd7e6;
}

.background-custodian-40 {
  background-color: #cbd7e6;
}

.color-custodian-gray-primary {
  color: #a7a9ac;
}

.background-custodian-gray-primary {
  background-color: #a7a9ac;
}

.color-custodian-gray-secondary {
  color: #6d6e71;
}

.background-custodian-gray-secondary {
  background-color: #6d6e71;
}

.color-private-wealth-100 {
  color: #6b3d86;
}

.background-private-wealth-100 {
  background-color: #6b3d86;
}

.color-private-wealth-50 {
  color: #ceb4dd;
}

.background-private-wealth-50 {
  background-color: #ceb4dd;
}

.color-private-wealth-25 {
  color: #efe6f4;
}

.background-private-wealth-25 {
  background-color: #efe6f4;
}

@font-face {
  font-display: auto;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: url("^assets/fonts/manrope-regular.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("^assets/fonts/manrope-bold.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: url("^assets/fonts/manrope-semi-bold.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  src: url("^assets/fonts/manrope-extra-bold.woff2") format("woff2");
  src: url("^assets/fonts/Montserrat-Bold.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url("^assets/fonts/Montserrat-Thin.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  src: url("^assets/fonts/Montserrat-Bold.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url("^assets/fonts/Montserrat-Medium.woff2") format("woff2");
}
.icon-8 {
  font-size: 8px !important; /* stylelint-disable-line declaration-no-important */
}

.icon-16 {
  font-size: 16px !important; /* stylelint-disable-line declaration-no-important */
}

.icon-24 {
  font-size: 24px !important; /* stylelint-disable-line declaration-no-important */
}

.icon-32 {
  font-size: 32px !important; /* stylelint-disable-line declaration-no-important */
}

.icon-40 {
  font-size: 40px !important; /* stylelint-disable-line declaration-no-important */
}

.icon-48 {
  font-size: 48px !important; /* stylelint-disable-line declaration-no-important */
}

.icon-64 {
  font-size: 64px !important; /* stylelint-disable-line declaration-no-important */
}

.text-enormous-normal {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.5;
}

.text-enormous-medium {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5;
}

.text-enormous-semi-bold {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.5;
}

.text-enormous-bold {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5;
}

.text-enormous-extra-bold {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.5;
}

.text-huge-normal {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.25;
}

.text-huge-medium {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
}

.text-huge-semi-bold {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
}

.text-huge-bold {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
}

.text-huge-extra-bold {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.25;
}

.text-big-normal {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.25;
}

.text-big-medium {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
}

.text-big-semi-bold {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
}

.text-big-bold {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
}

.text-big-extra-bold {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.25;
}

.text-increased-normal {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.text-increased-medium {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.text-increased-semi-bold {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.text-increased-bold {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.text-increased-extra-bold {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
}

.text-emphasized-normal {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
}

.text-emphasized-medium {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
}

.text-emphasized-semi-bold {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
}

.text-emphasized-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.33;
}

.text-emphasized-extra-bold {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
}

.text-regular-normal {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.text-regular-medium {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.text-regular-semi-bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.text-regular-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

.text-regular-extra-bold {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
}

.text-reduced-normal {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
}

.text-reduced-medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

.text-reduced-semi-bold {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
}

.text-reduced-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
}

.text-reduced-extra-bold {
  font-size: 14px;
  font-weight: 800;
  line-height: 1.14;
}

.text-small-normal {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
}

.text-small-medium {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
}

.text-small-semi-bold {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
}

.text-small-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
}

.text-small-extra-bold {
  font-size: 12px;
  font-weight: 800;
  line-height: 1.33;
}

.text-tiny-normal {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
}

.text-tiny-medium {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
}

.text-tiny-semi-bold {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
}

.text-tiny-bold {
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
}

.text-tiny-extra-bold {
  font-size: 10px;
  font-weight: 800;
  line-height: 1.4;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-extra-bold {
  font-weight: 800;
}

.font-size-enormous {
  font-size: 40px;
  line-height: 1.5;
}

.font-size-huge {
  font-size: 32px;
  line-height: 1.25;
}

.font-size-big {
  font-size: 24px;
  line-height: 1.25;
}

.font-size-increased {
  font-size: 20px;
  line-height: 1.2;
}

.font-size-emphasized {
  font-size: 18px;
  line-height: 1.33;
}

.font-size-regular {
  font-size: 16px;
  line-height: 1.5;
}

.font-size-reduced {
  font-size: 14px;
  line-height: 1.14;
}

.font-size-small {
  font-size: 12px;
  line-height: 1.33;
}

.font-size-tiny {
  font-size: 10px;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.h1 {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.25;
}

.h2 {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.33;
}

.h3 {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
}

.h4 {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
}

.m-0 {
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.m-0-5 {
  margin: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-1 {
  margin: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-1-5 {
  margin: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-2 {
  margin: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-3 {
  margin: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-4 {
  margin: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-5 {
  margin: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-6 {
  margin: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-7 {
  margin: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-8 {
  margin: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-9 {
  margin: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-0 {
  margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.mt-0-5 {
  margin-top: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-1 {
  margin-top: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-1-5 {
  margin-top: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-2 {
  margin-top: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-3 {
  margin-top: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-4 {
  margin-top: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-5 {
  margin-top: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-6 {
  margin-top: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-7 {
  margin-top: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-8 {
  margin-top: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.mt-9 {
  margin-top: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-0 {
  margin-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.mr-0-5 {
  margin-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-1 {
  margin-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-1-5 {
  margin-right: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-2 {
  margin-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-3 {
  margin-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-4 {
  margin-right: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-5 {
  margin-right: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-6 {
  margin-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-7 {
  margin-right: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-8 {
  margin-right: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-9 {
  margin-right: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-0 {
  margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.mb-0-5 {
  margin-bottom: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-1 {
  margin-bottom: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-1-5 {
  margin-bottom: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-2 {
  margin-bottom: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-3 {
  margin-bottom: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-4 {
  margin-bottom: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-5 {
  margin-bottom: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-6 {
  margin-bottom: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-7 {
  margin-bottom: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-8 {
  margin-bottom: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.mb-9 {
  margin-bottom: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-0 {
  margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.ml-0-5 {
  margin-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-1 {
  margin-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-1-5 {
  margin-left: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-2 {
  margin-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-3 {
  margin-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-4 {
  margin-left: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-5 {
  margin-left: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-6 {
  margin-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-7 {
  margin-left: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-8 {
  margin-left: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-9 {
  margin-left: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-0 {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.p-0-5 {
  padding: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-1 {
  padding: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-1-5 {
  padding: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-2 {
  padding: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-3 {
  padding: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-4 {
  padding: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-5 {
  padding: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-6 {
  padding: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-7 {
  padding: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-8 {
  padding: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-9 {
  padding: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-0 {
  padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.pt-0-5 {
  padding-top: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-1 {
  padding-top: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-1-5 {
  padding-top: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-2 {
  padding-top: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-3 {
  padding-top: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-4 {
  padding-top: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-5 {
  padding-top: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-6 {
  padding-top: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-7 {
  padding-top: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-8 {
  padding-top: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.pt-9 {
  padding-top: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-0 {
  padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.pr-0-5 {
  padding-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-1 {
  padding-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-1-5 {
  padding-right: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-2 {
  padding-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-3 {
  padding-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-4 {
  padding-right: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-5 {
  padding-right: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-6 {
  padding-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-7 {
  padding-right: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-8 {
  padding-right: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-9 {
  padding-right: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-0 {
  padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.pb-0-5 {
  padding-bottom: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-1 {
  padding-bottom: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-1-5 {
  padding-bottom: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-2 {
  padding-bottom: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-3 {
  padding-bottom: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-4 {
  padding-bottom: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-5 {
  padding-bottom: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-6 {
  padding-bottom: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-7 {
  padding-bottom: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-8 {
  padding-bottom: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.pb-9 {
  padding-bottom: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-0 {
  padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.pl-0-5 {
  padding-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-1 {
  padding-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-1-5 {
  padding-left: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-2 {
  padding-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-3 {
  padding-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-4 {
  padding-left: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-5 {
  padding-left: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-6 {
  padding-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-7 {
  padding-left: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-8 {
  padding-left: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-9 {
  padding-left: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-0 {
  margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.my-0 {
  margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.px-0 {
  padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.py-0 {
  padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.mx-0-5 {
  margin-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-0-5 {
  margin-bottom: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-0-5 {
  padding-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-0-5 {
  padding-bottom: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-1 {
  margin-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-1 {
  margin-bottom: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-1 {
  padding-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-1 {
  padding-bottom: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-1-5 {
  margin-left: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-1-5 {
  margin-bottom: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-1-5 {
  padding-left: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-1-5 {
  padding-bottom: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-2 {
  margin-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-2 {
  margin-bottom: 1rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-2 {
  padding-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-2 {
  padding-bottom: 1rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-3 {
  margin-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-3 {
  margin-bottom: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-3 {
  padding-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-3 {
  padding-bottom: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-4 {
  margin-left: 2rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-4 {
  margin-bottom: 2rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-4 {
  padding-left: 2rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-4 {
  padding-bottom: 2rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 2rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-5 {
  margin-left: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-5 {
  margin-bottom: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-5 {
  padding-left: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-5 {
  padding-bottom: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 2.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-6 {
  margin-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-6 {
  margin-bottom: 3rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-6 {
  padding-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-6 {
  padding-bottom: 3rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-7 {
  margin-left: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-7 {
  margin-bottom: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-7 {
  padding-left: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-7 {
  padding-bottom: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 3.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-8 {
  margin-left: 4rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-8 {
  margin-bottom: 4rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-8 {
  padding-left: 4rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-8 {
  padding-bottom: 4rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 4rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-9 {
  margin-left: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.my-9 {
  margin-bottom: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-9 {
  padding-left: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.py-9 {
  padding-bottom: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 4.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.border-radius-0 {
  border-radius: 0;
}

.border-radius-0-5 {
  border-radius: 0.25rem;
}

.border-radius-1 {
  border-radius: 0.5rem;
}

.border-radius-1-5 {
  border-radius: 0.75rem;
}

.border-radius-2 {
  border-radius: 1rem;
}

.border-radius-3 {
  border-radius: 1.5rem;
}

.border-radius-4 {
  border-radius: 2rem;
}

.border-radius-5 {
  border-radius: 2.5rem;
}

.border-radius-6 {
  border-radius: 3rem;
}

.border-radius-7 {
  border-radius: 3.5rem;
}

.border-radius-8 {
  border-radius: 4rem;
}

.border-radius-9 {
  border-radius: 4.5rem;
}

.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 2.5rem;
  width: 1280px;
}

.loading-indicator-backdrop {
  background: rgba(255, 255, 255, 0.8);
}

.mat-autocomplete-panel {
  background-color: #fff;
  box-shadow: 0 4px 24px rgba(91, 98, 121, 0.14);
  margin-top: 0.5rem;
}

.button {
  align-items: center;
  background-color: #4dccb4;
  border: none;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 1.5;
  padding: 0.5rem 2rem;
}
.button svg-icon:first-of-type {
  margin-left: 0.25rem;
}
.button svg-icon:last-of-type {
  margin-right: 0.25rem;
}
.button:focus {
  outline: none;
}
.button:hover {
  background-color: #71d6c3;
}
.button:disabled {
  background-color: #efeff2;
  color: #c0c3cc;
  cursor: unset;
}
.button.button-red {
  background-color: #fb6a72;
}
.button.button-red:hover {
  background-color: #fc888e;
}
.button.button-red:disabled {
  background-color: #efeff2;
}
.button.hover-dark:disabled {
  background-color: #c0c3cc;
  color: #fff;
}
.button .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.3) !important; /* stylelint-disable-line declaration-no-important */
}

.button.button-secondary {
  background-color: #fff;
  border: 1px solid #4dccb4;
  color: #4dccb4;
}
.button.button-secondary:hover {
  border: 1px solid #71d6c3;
  color: #71d6c3;
}
.button.button-secondary .mat-ripple-element {
  background-color: rgba(77, 204, 180, 0.3) !important; /* stylelint-disable-line declaration-no-important */
}
.button.button-secondary.button-red {
  border: 1px solid #fb6a72;
  color: #fb6a72;
}
.button.button-secondary.button-red:not(:disabled):hover {
  background-color: #fff;
  border: 1px solid #fc888e;
  color: #fc888e;
}
.button.button-secondary.button-red .mat-ripple-element {
  background-color: rgba(251, 106, 114, 0.3) !important; /* stylelint-disable-line declaration-no-important */
}
.button.button-secondary:disabled {
  background-color: #fff;
  border: 1px solid #c0c3cc;
  color: #c0c3cc;
}

.button.button-tertiary {
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: #4dccb4;
  padding: 0;
}
.button.button-tertiary:hover {
  background-color: transparent;
  color: #71d6c3;
}
.button.button-tertiary.button-red {
  color: #fb6a72;
}
.button.button-tertiary.button-red:not(:disabled):hover {
  color: #fc888e;
}
.button.button-tertiary:disabled {
  color: #c0c3cc;
}

.button-mini {
  font-size: 14px;
  line-height: 16px;
}

.button.custodian {
  background-color: #3a5074;
}
.button.custodian:hover {
  background-color: #5d82b2;
}
.button.custodian:disabled {
  background-color: #c0c3cc;
  color: #fff;
}
.button.custodian .mat-ripple-element {
  background-color: rgba(93, 130, 178, 0.3) !important; /* stylelint-disable-line declaration-no-important */
}

.button.private-wealth {
  background-color: #6b3d86;
}
.button.private-wealth:hover {
  background-color: #ceb4dd;
}
.button.private-wealth:disabled {
  background-color: #efe6f4;
  color: #fff;
}
.button.private-wealth .mat-ripple-element {
  background-color: rgba(206, 180, 221, 0.3) !important; /* stylelint-disable-line declaration-no-important */
}

.button.private-wealth-secondary {
  background-color: #fff;
  border: 1px solid #6b3d86;
  color: #6b3d86;
}
.button.private-wealth-secondary:hover {
  border: 1px solid #ceb4dd;
  color: #ceb4dd;
}
.button.private-wealth-secondary .mat-ripple-element {
  background-color: rgba(77, 204, 180, 0.3) !important; /* stylelint-disable-line declaration-no-important */
}

.button.private-wealth-tertiary {
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: #6b3d86;
  padding: 0;
}
.button.private-wealth-tertiary:hover {
  background-color: transparent;
  color: #ceb4dd;
}
.button.private-wealth-tertiary.button-red {
  color: #fb6a72;
}
.button.private-wealth-tertiary.button-red:not(:disabled):hover {
  color: #fc888e;
}
.button.private-wealth-tertiary:disabled {
  color: #c0c3cc;
}

.button.private-wealth-mini {
  background-color: #6b3d86;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

.button.button-secondary.custodian {
  background-color: #fff;
  border: 1px solid #3a5074;
  color: #3a5074;
}
.button.button-secondary.custodian:hover {
  border: 1px solid #5d82b2;
  color: #5d82b2;
}
.button.button-secondary.custodian .mat-ripple-element {
  background-color: rgba(93, 130, 178, 0.3) !important; /* stylelint-disable-line declaration-no-important */
}
.button.button-secondary.custodian:disabled {
  background-color: #fff;
  border: 1px solid #c0c3cc;
  color: #c0c3cc;
}

.wtax-checkbox .mat-checkbox-layout {
  margin: 0;
}
.wtax-checkbox .mat-checkbox-frame {
  background-color: #fff;
  border-color: #dee0e4;
  border-width: 1px;
}
.wtax-checkbox .mat-checkbox-background,
.wtax-checkbox .mat-checkbox-frame {
  border-radius: 4px;
}
.wtax-checkbox .mat-checkbox-inner-container {
  height: 24px;
  width: 24px;
}
.wtax-checkbox .mat-checkbox-checkmark {
  height: 20px;
  margin: auto;
  width: 20px;
}
.wtax-checkbox.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .wtax-checkbox.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b8ebe1;
}
.wtax-checkbox.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  background-color: #efeff2;
  border-color: #dee0e4;
}
.wtax-checkbox.custodian.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #5d82b2;
}
.wtax-checkbox.private-wealth.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ceb4dd;
}

.message-dialog-backdrop {
  background-color: rgba(255, 255, 255, 0.4);
}

.dialog-backdrop {
  background-color: rgba(62, 65, 70, 0.6);
}

.dialog-container {
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.dialog-title {
  color: #3e4146;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.dialog-content {
  flex: 1;
  overflow: auto;
  padding: 2.5rem;
}

.wtax-input {
  background-color: transparent;
  border: 0;
  color: #0e1118;
  height: 24px;
  padding: 0;
  width: 100%;
}
.wtax-input:focus {
  outline: none;
}
.wtax-input::placeholder {
  color: #9da1af;
}

.wtax-datepicker-input {
  width: 300px;
}

.wtax-radio-group .mat-radio-button {
  /*disabled*/
}
.wtax-radio-group .mat-radio-button .mat-radio-container,
.wtax-radio-group .mat-radio-button .mat-radio-outer-circle,
.wtax-radio-group .mat-radio-button .mat-radio-inner-circle {
  height: 24px;
  width: 24px;
}
.wtax-radio-group .mat-radio-button .mat-radio-outer-circle {
  background-color: #fff;
  border-color: #dee0e4;
  border-width: 1px;
}
.wtax-radio-group .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-width: 2px;
}
.wtax-radio-group .mat-radio-button.mat-radio-disabled:not(.mat-radio-checked) .mat-radio-outer-circle {
  background-color: #efeff2;
  border-color: #dee0e4;
  border-width: 1px;
}
.wtax-radio-group .mat-radio-button.mat-radio-checked.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #b8ebe1;
  border-width: 2px;
}
.wtax-radio-group .mat-radio-button.mat-radio-checked.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #b8ebe1;
}
.wtax-radio-group.wtax-radio-group-row {
  column-gap: 2rem;
  display: inline-grid;
  grid-auto-flow: column;
}

/**
 * WTax style override for Material tabs. Use this class on <mat-tab-group>.
 */
.wtax-tabs {
  --label-padding: 16px;
  --border-radius: 16px;
}
.wtax-tabs .mat-focus-indicator {
  color: #0e1118;
}
.wtax-tabs .mat-tab-header {
  border-bottom: none;
  overflow: unset;
}
.wtax-tabs .mat-tab-label-container {
  overflow: unset;
}
.wtax-tabs .mat-tab-label,
.wtax-tabs .mat-tab-link {
  background-color: #efeff2;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  color: #0e1118;
  height: unset;
  opacity: 1;
  padding: var(--label-padding) var(--label-padding) calc(var(--label-padding) + var(--border-radius));
  transition: all 0.125s;
}
.wtax-tabs .mat-tab-label:not(:first-child),
.wtax-tabs .mat-tab-link:not(:first-child) {
  margin-left: 3px;
}
.wtax-tabs .mat-tab-link {
  margin-bottom: -1px;
}
.wtax-tabs .mat-tab-label-active {
  background-color: #fff;
  box-shadow: 0 4px 24px rgba(91, 98, 121, 0.14);
  font-weight: 800;
  z-index: 1;
}
.wtax-tabs .mat-tab-label-active:not(:first-child)::before {
  background-image: radial-gradient(circle at 0 0, transparent var(--border-radius), #fff var(--border-radius));
  bottom: var(--border-radius);
  content: "";
  height: var(--border-radius);
  position: absolute;
  right: 100%;
  width: var(--border-radius);
}
.wtax-tabs .mat-tab-label-active::after {
  background-image: radial-gradient(circle at 100% 0, transparent var(--border-radius), #fff var(--border-radius));
  bottom: var(--border-radius);
  content: "";
  height: var(--border-radius);
  left: 100%;
  position: absolute;
  width: var(--border-radius);
}
.wtax-tabs .mat-tab-label-active.mat-ripple {
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */
}
.wtax-tabs .mat-tab-label-content {
  position: relative;
  width: 100%;
}
.wtax-tabs .mat-tab-body,
.wtax-tabs .mat-tab-body .mat-tab-body-content {
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */
}
.wtax-tabs .mat-ripple-element {
  display: none;
}
.wtax-tabs .tab-badge,
.wtax-tabs .tab-completeness-indicator {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.wtax-tabs .mat-ink-bar {
  background-color: #4dccb4;
  bottom: var(--border-radius);
  clip-path: polygon(var(--label-padding) 0, calc(100% - 16px) 0, calc(100% - 16px) 100%, var(--label-padding) 100%);
  height: 3px;
  z-index: 1;
}
.wtax-tabs .mat-tab-body-wrapper {
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 24px 0 rgba(91, 98, 121, 0.1);
  margin-top: calc(-1 * var(--border-radius));
  z-index: 1;
}
.wtax-tabs .mat-tab-body {
  padding: 2.5rem;
}

.tabs-full-width-labels .mat-tab-label {
  flex: 1;
  font-size: 90%;
}
.tabs-full-width-labels .mat-tab-label-active:last-child::after {
  display: none;
}

.tab-badge {
  align-items: center;
  background-color: #ffa842;
  border-radius: 8px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  min-height: 24px;
  min-width: 24px;
  padding: 0 0.25rem;
}

mat-tab.no-body-padding .mat-tab-body {
  padding: 0;
}

.wtax-toggle.mat-disabled .mat-slide-toggle-bar {
  background-color: #7c8194;
}
.wtax-toggle.mat-disabled .mat-slide-toggle-thumb {
  background-color: #c0c3cc;
}
.wtax-toggle .mat-slide-toggle-bar {
  background-color: #efeff2;
}
.wtax-toggle .mat-slide-toggle-thumb {
  background-color: #fafafb;
}

.wtax-input {
  background-color: transparent;
  border: 0;
  color: #0e1118;
  height: 24px;
  padding: 0;
  width: 100%;
}
.wtax-input:focus {
  outline: none;
}
.wtax-input::placeholder {
  color: #9da1af;
}

.wtax-datepicker-input {
  width: 300px;
}

table.private-wealth-table.selectable, table.private-wealth-table.borderless, table.wtax-table.selectable, table.wtax-table.borderless {
  background-color: #fafafb;
  box-shadow: none;
}
table.private-wealth-table.selectable th, table.private-wealth-table.borderless th, table.wtax-table.selectable th, table.wtax-table.borderless th {
  background-color: unset;
}
table.private-wealth-table.selectable tbody, table.private-wealth-table.borderless tbody, table.wtax-table.selectable tbody, table.wtax-table.borderless tbody {
  background-color: #fff;
}
table.private-wealth-table.selectable tbody::before, table.private-wealth-table.borderless tbody::before, table.wtax-table.selectable tbody::before, table.wtax-table.borderless tbody::before {
  content: "";
  display: table-row;
  height: 0;
}
table.private-wealth-table.selectable tbody tr:first-of-type td:first-child, table.private-wealth-table.borderless tbody tr:first-of-type td:first-child, table.wtax-table.selectable tbody tr:first-of-type td:first-child, table.wtax-table.borderless tbody tr:first-of-type td:first-child {
  border-top-left-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr:first-of-type td:last-child, table.private-wealth-table.borderless tbody tr:first-of-type td:last-child, table.wtax-table.selectable tbody tr:first-of-type td:last-child, table.wtax-table.borderless tbody tr:first-of-type td:last-child {
  border-top-right-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr:last-of-type td:first-child, table.private-wealth-table.borderless tbody tr:last-of-type td:first-child, table.wtax-table.selectable tbody tr:last-of-type td:first-child, table.wtax-table.borderless tbody tr:last-of-type td:first-child {
  border-bottom-left-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr:last-of-type td:last-child, table.private-wealth-table.borderless tbody tr:last-of-type td:last-child, table.wtax-table.selectable tbody tr:last-of-type td:last-child, table.wtax-table.borderless tbody tr:last-of-type td:last-child {
  border-bottom-right-radius: 0.5rem;
}
table.private-wealth-table.selectable td, table.private-wealth-table.borderless td, table.wtax-table.selectable td, table.wtax-table.borderless td {
  border-top: none;
}

table.wtax-table {
  background-color: #fff;
  border-collapse: separate;
  border-radius: 1rem;
  border-spacing: 0;
  box-shadow: 0 4px 24px 0 rgba(91, 98, 121, 0.14);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
  padding: 0.5rem;
  text-align: left;
}
table.wtax-table th {
  background-color: #efeff2;
  color: #5b6279;
  font-weight: 400;
  padding: 1rem 0.25rem;
}
table.wtax-table th:first-of-type {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding-left: 1rem;
}
table.wtax-table th:last-of-type {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-right: 1rem;
}
table.wtax-table tbody::before {
  content: "";
  display: table-row;
  height: 0.5rem;
}
table.wtax-table td {
  border-top: 1px #efeff2 solid;
  color: #0e1118;
  height: 64px;
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
table.wtax-table td:first-child {
  padding-left: 1rem;
}
table.wtax-table td:last-child {
  padding-right: 1rem;
}
table.wtax-table td.loading {
  text-align: center;
}

table.wtax-table:not(.selectable) tr.expandable td {
  height: auto;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  vertical-align: top;
}

table.wtax-table.selectable tbody td {
  height: auto;
  padding: 0;
}
table.wtax-table.selectable tbody td .cell {
  align-items: center;
  border-bottom: 1px transparent solid;
  border-top: 1px transparent solid;
  display: flex;
  height: 48px;
  margin: 0.25rem 0;
  padding: 0.5rem 0.25rem;
}
table.wtax-table.selectable tbody td:first-child .cell {
  border-left: 1px transparent solid;
  margin-left: 0.5rem;
  padding-left: 1rem;
}
table.wtax-table.selectable tbody td:last-child .cell {
  border-right: 1px transparent solid;
  margin-right: 0.5rem;
  padding-right: 1rem;
}
table.wtax-table.selectable tbody tr.selected td .cell {
  background-color: #edfaf7;
  border-color: #4dccb4;
}
table.wtax-table.selectable tbody tr.selected td:first-of-type .cell {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
table.wtax-table.selectable tbody tr.selected td:last-of-type .cell {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
table.wtax-table.selectable tbody tr.expandable td {
  height: auto;
  vertical-align: top;
}
table.wtax-table.selectable tbody tr.expandable td .cell {
  height: auto;
  min-height: 48px;
}
table.wtax-table.selectable tbody tr.expandable td .expansion-button {
  height: calc(48px - 1rem);
}
table.wtax-table.selectable tbody tr.expandable td.expandable {
  width: 40%;
}
table.wtax-table.selectable tbody tr.expandable td:not(.expandable) {
  position: relative;
}
table.wtax-table.selectable tbody tr.expandable td:not(.expandable) .cell {
  align-items: flex-start;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
table.wtax-table.selectable tbody tr.expandable td:not(.expandable) .cell-content {
  align-items: center;
  display: flex;
  height: calc(48px - 1rem);
}
table.wtax-table.selectable mat-radio-button .mat-radio-label {
  margin-bottom: 0;
}
table.wtax-table.selectable mat-radio-button .mat-radio-outer-circle {
  border-color: #c0c3cc;
  border-width: 1px;
}
table.wtax-table.selectable mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(1);
}

.card-container {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 4px 30px 0 rgba(91, 98, 121, 0.14), 0 -10px 20px 0 rgba(255, 255, 255, 0.4);
  padding: 1.5rem;
}
.card-container.dark {
  background-color: #fafafb;
  box-shadow: none;
}

.bullet-list {
  padding-left: 1.25rem;
}
.bullet-list li::marker {
  color: #72d28d;
}
.bullet-list > li:not(:first-child) {
  margin-top: 0.5rem;
}

.custodian-bullet-list {
  padding-left: 1.25rem;
}
.custodian-bullet-list li::marker {
  color: #3a5074;
}

.private-wealth-bullet-list {
  padding-left: 1.25rem;
}
.private-wealth-bullet-list li::marker {
  color: #6b3d86;
}
.private-wealth-bullet-list > li:not(:first-child) {
  margin-top: 0.5rem;
}

.overlay-container {
  position: relative;
}
.overlay-container .overlay {
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

table.private-wealth-table.selectable, table.private-wealth-table.borderless, table.wtax-table.borderless, table.wtax-table.selectable {
  background-color: #fafafb;
  box-shadow: none;
}
table.private-wealth-table.selectable th, table.private-wealth-table.borderless th, table.wtax-table.borderless th, table.wtax-table.selectable th {
  background-color: unset;
}
table.private-wealth-table.selectable tbody, table.private-wealth-table.borderless tbody, table.wtax-table.borderless tbody, table.wtax-table.selectable tbody {
  background-color: #fff;
}
table.private-wealth-table.selectable tbody::before, table.private-wealth-table.borderless tbody::before, table.wtax-table.borderless tbody::before, table.wtax-table.selectable tbody::before {
  content: "";
  display: table-row;
  height: 0;
}
table.private-wealth-table.selectable tbody tr:first-of-type td:first-child, table.private-wealth-table.borderless tbody tr:first-of-type td:first-child, table.wtax-table.borderless tbody tr:first-of-type td:first-child, table.wtax-table.selectable tbody tr:first-of-type td:first-child {
  border-top-left-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr:first-of-type td:last-child, table.private-wealth-table.borderless tbody tr:first-of-type td:last-child, table.wtax-table.borderless tbody tr:first-of-type td:last-child, table.wtax-table.selectable tbody tr:first-of-type td:last-child {
  border-top-right-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr:last-of-type td:first-child, table.private-wealth-table.borderless tbody tr:last-of-type td:first-child, table.wtax-table.borderless tbody tr:last-of-type td:first-child, table.wtax-table.selectable tbody tr:last-of-type td:first-child {
  border-bottom-left-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr:last-of-type td:last-child, table.private-wealth-table.borderless tbody tr:last-of-type td:last-child, table.wtax-table.borderless tbody tr:last-of-type td:last-child, table.wtax-table.selectable tbody tr:last-of-type td:last-child {
  border-bottom-right-radius: 0.5rem;
}
table.private-wealth-table.selectable td, table.private-wealth-table.borderless td, table.wtax-table.borderless td, table.wtax-table.selectable td {
  border-top: none;
}

table.private-wealth-table {
  background-color: #fff;
  border-collapse: separate;
  border-radius: 1rem;
  border-spacing: 0;
  box-shadow: 0 4px 24px 0 rgba(91, 98, 121, 0.14);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
  padding: 0.5rem;
  text-align: left;
}
table.private-wealth-table th {
  background-color: #efeff2;
  color: #5b6279;
  font-weight: 400;
  padding: 1rem 0.25rem;
}
table.private-wealth-table th:first-of-type {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding-left: 1rem;
}
table.private-wealth-table th:last-of-type {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-right: 1rem;
}
table.private-wealth-table tbody::before {
  content: "";
  display: table-row;
  height: 0.5rem;
}
table.private-wealth-table td {
  border-top: 1px #efeff2 solid;
  color: #0e1118;
  height: 64px;
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
table.private-wealth-table td:first-child {
  padding-left: 1rem;
}
table.private-wealth-table td:last-child {
  padding-right: 1rem;
}
table.private-wealth-table td.loading {
  text-align: center;
}

table.private-wealth-table:not(.selectable) tr.expandable td {
  height: auto;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  vertical-align: top;
}

table.private-wealth-table.selectable tbody td {
  height: auto;
  padding: 0;
}
table.private-wealth-table.selectable tbody td .cell {
  align-items: center;
  border-bottom: 1px transparent solid;
  border-top: 1px transparent solid;
  display: flex;
  height: 48px;
  margin: 0.25rem 0;
  padding: 0.5rem 0.25rem;
}
table.private-wealth-table.selectable tbody td:first-child .cell {
  border-left: 1px transparent solid;
  margin-left: 0.5rem;
  padding-left: 1rem;
}
table.private-wealth-table.selectable tbody td:last-child .cell {
  border-right: 1px transparent solid;
  margin-right: 0.5rem;
  padding-right: 1rem;
}
table.private-wealth-table.selectable tbody tr.selected td .cell {
  border-color: #6b3d86;
}
table.private-wealth-table.selectable tbody tr.selected td:first-of-type .cell {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr.selected td:last-of-type .cell {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
table.private-wealth-table.selectable tbody tr.expandable td {
  height: auto;
  vertical-align: top;
}
table.private-wealth-table.selectable tbody tr.expandable td .cell {
  height: auto;
  min-height: 48px;
}
table.private-wealth-table.selectable tbody tr.expandable td .expansion-button {
  height: calc(48px - 1rem);
}
table.private-wealth-table.selectable tbody tr.expandable td.expandable {
  width: 40%;
}
table.private-wealth-table.selectable tbody tr.expandable td:not(.expandable) {
  position: relative;
}
table.private-wealth-table.selectable tbody tr.expandable td:not(.expandable) .cell {
  align-items: flex-start;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
table.private-wealth-table.selectable tbody tr.expandable td:not(.expandable) .cell-content {
  align-items: center;
  display: flex;
  height: calc(48px - 1rem);
}
table.private-wealth-table.selectable mat-radio-button .mat-radio-label {
  margin-bottom: 0;
}
table.private-wealth-table.selectable mat-radio-button .mat-radio-outer-circle {
  border-width: 1px;
}
table.private-wealth-table.selectable mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  background-color: #6b3d86;
  transform: scale(1);
}

.private-wealth-radio-group .mat-radio-button {
  /*disabled*/
}
.private-wealth-radio-group .mat-radio-button .mat-radio-container,
.private-wealth-radio-group .mat-radio-button .mat-radio-outer-circle,
.private-wealth-radio-group .mat-radio-button .mat-radio-inner-circle {
  height: 24px;
  width: 24px;
}
.private-wealth-radio-group .mat-radio-button .mat-radio-outer-circle {
  background-color: #fff;
  border-color: #ceb4dd;
  border-width: 1px;
}
.private-wealth-radio-group .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-width: 2px;
}
.private-wealth-radio-group .mat-radio-button.mat-radio-disabled:not(.mat-radio-checked) .mat-radio-outer-circle {
  background-color: #ceb4dd;
  border-color: #ceb4dd;
  border-width: 1px;
}
.private-wealth-radio-group .mat-radio-button.mat-radio-checked.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #ceb4dd;
  border-width: 2px;
}
.private-wealth-radio-group .mat-radio-button.mat-radio-checked.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #ceb4dd;
}
.private-wealth-radio-group .mat-radio-button.mat-primary .mat-radio-inner-circle,
.private-wealth-radio-group .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.private-wealth-radio-group .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.private-wealth-radio-group .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #6b3d86;
}
.private-wealth-radio-group .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #6b3d86;
}
.private-wealth-radio-group.private-wealth-radio-group {
  column-gap: 2rem;
  display: inline-grid;
  grid-auto-flow: column;
}

/**
 * WTax style override for Material tabs. Use this class on <mat-tab-group>.
 */
.private-wealth-tabs {
  --label-padding: 16px;
  --border-radius: 16px;
}
.private-wealth-tabs .mat-tab-header {
  border-bottom: none;
  overflow: unset;
}
.private-wealth-tabs .mat-tab-label-container {
  overflow: unset;
}
.private-wealth-tabs .mat-tab-label,
.private-wealth-tabs .mat-tab-link {
  background-color: #efeff2;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  height: unset;
  opacity: 1;
  padding: var(--label-padding) var(--label-padding) calc(var(--label-padding) + var(--border-radius));
  transition: all 0.125s;
}
.private-wealth-tabs .mat-tab-label:not(:first-child),
.private-wealth-tabs .mat-tab-link:not(:first-child) {
  margin-left: 3px;
}
.private-wealth-tabs .mat-tab-link {
  margin-bottom: -1px;
}
.private-wealth-tabs .mat-tab-label-active {
  background-color: #fff;
  box-shadow: 0 4px 24px rgba(91, 98, 121, 0.14);
  font-weight: 800;
  z-index: 1;
}
.private-wealth-tabs .mat-tab-label-active:not(:first-child)::before {
  background-image: radial-gradient(circle at 0 0, transparent var(--border-radius), #fff var(--border-radius));
  bottom: var(--border-radius);
  content: "";
  height: var(--border-radius);
  position: absolute;
  right: 100%;
  width: var(--border-radius);
}
.private-wealth-tabs .mat-tab-label-active::after {
  background-image: radial-gradient(circle at 100% 0, transparent var(--border-radius), #fff var(--border-radius));
  bottom: var(--border-radius);
  content: "";
  height: var(--border-radius);
  left: 100%;
  position: absolute;
  width: var(--border-radius);
}
.private-wealth-tabs .mat-tab-label-active.mat-ripple {
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */
}
.private-wealth-tabs .mat-tab-label-content {
  position: relative;
  width: 100%;
}
.private-wealth-tabs .mat-tab-body,
.private-wealth-tabs .mat-tab-body .mat-tab-body-content {
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */
}
.private-wealth-tabs .mat-ripple-element {
  display: none;
}
.private-wealth-tabs .tab-badge,
.private-wealth-tabs .tab-completeness-indicator {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.private-wealth-tabs .mat-ink-bar {
  background-color: #6b3d86 !important; /* stylelint-disable-line declaration-no-important */
  bottom: var(--border-radius);
  clip-path: polygon(var(--label-padding) 0, calc(100% - var(--label-padding)) 0, calc(100% - var(--label-padding)) 100%, var(--label-padding) 100%);
  height: 3px;
  z-index: 1;
}
.private-wealth-tabs .mat-tab-body-wrapper {
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 24px 0 rgba(91, 98, 121, 0.1);
  margin-top: calc(-1 * var(--border-radius));
  z-index: 1;
}
.private-wealth-tabs .mat-tab-body {
  padding: 2.5rem;
}

.tabs-full-width-labels .mat-tab-label {
  flex: 1;
  font-size: 85%;
}
.tabs-full-width-labels .mat-tab-label-active:last-child::after {
  display: none;
}

.tab-badge {
  align-items: center;
  background-color: #ffa842;
  border-radius: 8px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  min-height: 24px;
  min-width: 24px;
  padding: 0 0.25rem;
}

mat-tab.no-body-padding .mat-tab-body {
  padding: 0;
}
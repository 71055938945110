@use 'abstracts/variables';

@font-face {
  font-display: auto;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: map-get(variables.$font-weights, 'normal');
  src: url('^assets/fonts/Montserrat-Thin.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: map-get(variables.$font-weights, 'bold');
  src: url('^assets/fonts/Montserrat-Bold.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: map-get(variables.$font-weights, 'medium');
  src: url('^assets/fonts/Montserrat-Medium.woff2') format('woff2');
}

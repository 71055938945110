@use 'abstracts/variables';
@use 'abstracts/functions';

.bullet-list {
  padding-left: functions.spacing(2.5);

  li::marker {
    color: map-get(variables.$wtax-state, 'success');
  }

  > li:not(:first-child) {
    margin-top: functions.spacing(1);
  }
}

.custodian-bullet-list {
  padding-left: functions.spacing(2.5);

  li::marker {
    color: map-get(variables.$wtax-custodian, 100);
  }
}

.private-wealth-bullet-list {
  padding-left: functions.spacing(2.5);

  li::marker {
    color: map-get(variables.$wtax-private-wealth, 100);
  }

  > li:not(:first-child) {
    margin-top: functions.spacing(1);
  }
}

@use 'abstracts/variables';
@use 'abstracts/functions';

.message-dialog-backdrop {
  background-color: rgba(map-get(variables.$wtax-white, 100), 0.4);
}

.dialog-backdrop {
  background-color: rgba(map-get(variables.$wtax-black, 80), 0.6);
}

.dialog-container {
  background-color: map-get(variables.$wtax-white, 100);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.dialog-title {
  color: map-get(variables.$wtax-black, 80);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: functions.spacing(3);
}

.dialog-content {
  flex: 1;
  overflow: auto;
  padding: functions.spacing(5);
}

.overlay-container {
  position: relative;

  .overlay {
    background-color: rgba($color: #fff, $alpha: 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

@use 'abstracts/variables';
@use 'abstracts/functions';

%borderless-table {
  background-color: map-get(variables.$wtax-blue-dark, 03);
  box-shadow: none;

  th {
    background-color: unset;
  }

  tbody {
    background-color: map-get(variables.$wtax-white, 100);

    &::before {
      content: '';
      display: table-row;
      height: 0;
    }

    tr:first-of-type {
      td:first-child {
        border-top-left-radius: functions.spacing(1);
      }

      td:last-child {
        border-top-right-radius: functions.spacing(1);
      }
    }
    tr:last-of-type {
      td:first-child {
        border-bottom-left-radius: functions.spacing(1);
      }

      td:last-child {
        border-bottom-right-radius: functions.spacing(1);
      }
    }
  }

  td {
    border-top: none;
  }
}

table.wtax-table {
  background-color: map-get(variables.$wtax-white, 100);
  border-collapse: separate;
  border-radius: functions.spacing(2);
  border-spacing: 0;
  box-shadow: 0 4px 24px 0 rgba(91, 98, 121, 0.14);
  font-size: map-get(map-get(variables.$paragraphs, 'reduced'), 'font-size');
  font-weight: map-get(variables.$font-weights, 'normal');
  line-height: map-get(map-get(variables.$paragraphs, 'reduced'), 'line-height');
  padding: functions.spacing(1);
  text-align: left;

  th {
    background-color: map-get(variables.$wtax-blue-dark, 10);
    color: map-get(variables.$wtax-blue-dark, 100);
    font-weight: map-get(variables.$font-weights, 'normal');
    padding: functions.spacing(2) functions.spacing(0.5);

    &:first-of-type {
      border-bottom-left-radius: functions.spacing(1);
      border-top-left-radius: functions.spacing(1);
      padding-left: functions.spacing(2);
    }

    &:last-of-type {
      border-bottom-right-radius: functions.spacing(1);
      border-top-right-radius: functions.spacing(1);
      padding-right: functions.spacing(2);
    }
  }

  tbody::before {
    content: '';
    display: table-row;
    height: functions.spacing(1);
  }

  td {
    border-top: 1px map-get(variables.$wtax-blue-dark, 10) solid;
    color: map-get(variables.$wtax-black, 100);
    height: 64px; // 48px height + vertical padding (2 * 8px)
    padding: functions.spacing(1) functions.spacing(0.5);
    vertical-align: middle;

    &:first-child {
      padding-left: functions.spacing(2);
    }
    &:last-child {
      padding-right: functions.spacing(2);
    }
  }

  td.loading {
    text-align: center;
  }
}

table.wtax-table:not(.selectable) {
  tr.expandable td {
    height: auto;
    padding-bottom: functions.spacing(3);
    padding-top: functions.spacing(3);
    vertical-align: top;
  }
}

table.wtax-table.borderless {
  @extend %borderless-table;
}

table.wtax-table.selectable {
  @extend %borderless-table;

  tbody {
    td {
      height: auto;
      padding: 0;

      .cell {
        align-items: center;
        border-bottom: 1px transparent solid;
        border-top: 1px transparent solid;
        display: flex;
        height: 48px;
        margin: functions.spacing(0.5) 0;
        padding: functions.spacing(1) functions.spacing(0.5);
      }

      &:first-child .cell {
        border-left: 1px transparent solid;
        margin-left: functions.spacing(1);
        padding-left: functions.spacing(2);
      }

      &:last-child .cell {
        border-right: 1px transparent solid;
        margin-right: functions.spacing(1);
        padding-right: functions.spacing(2);
      }
    }

    tr.selected {
      td .cell {
        background-color: map-get(variables.$wtax-green, 10);
        border-color: map-get(variables.$wtax-green, 100);
      }

      td:first-of-type .cell {
        border-bottom-left-radius: functions.spacing(1);
        border-top-left-radius: functions.spacing(1);
      }

      td:last-of-type .cell {
        border-bottom-right-radius: functions.spacing(1);
        border-top-right-radius: functions.spacing(1);
      }
    }

    tr.expandable td {
      height: auto;
      vertical-align: top;

      .cell {
        height: auto;
        min-height: 48px;
      }

      .expansion-button {
        height: calc(48px - 1rem);
      }
    }

    tr.expandable td.expandable {
      width: 40%;
    }

    tr.expandable td:not(.expandable) {
      position: relative;

      .cell {
        align-items: flex-start;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      .cell-content {
        align-items: center;
        display: flex;
        height: calc(48px - 1rem);
      }
    }
  }

  mat-radio-button {
    .mat-radio-label {
      margin-bottom: 0;
    }

    .mat-radio-outer-circle {
      border-color: map-get(variables.$wtax-blue-dark, 40);
      border-width: 1px;
    }

    &.mat-radio-checked .mat-radio-inner-circle {
      transform: scale(1);
    }
  }
}

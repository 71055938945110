@use 'abstracts/variables';

.wtax-input {
  background-color: transparent;
  border: 0;
  color: map-get(variables.$wtax-black, 100);
  height: 24px;
  padding: 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: map-get(variables.$wtax-blue-dark, 60);
  }
}

.wtax-datepicker-input {
  width: 300px;
}

@use 'abstracts/variables';
@use 'abstracts/functions';

.wtax-radio-group {
  .mat-radio-button {
    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      height: 24px;
      width: 24px;
    }

    .mat-radio-outer-circle {
      background-color: #fff;
      border-color: map-get(variables.$wtax-blue-dark, 20);
      border-width: 1px;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-width: 2px;
      }
    }

    /*disabled*/
    &.mat-radio-disabled:not(.mat-radio-checked) .mat-radio-outer-circle {
      background-color: map-get(variables.$wtax-blue-dark, 10);
      border-color: map-get(variables.$wtax-blue-dark, 20);
      border-width: 1px;
    }
    &.mat-radio-checked.mat-radio-disabled {
      .mat-radio-outer-circle {
        border-color: map-get(variables.$wtax-green, 40);
        border-width: 2px;
      }
      .mat-radio-inner-circle {
        background-color: map-get(variables.$wtax-green, 40);
      }
    }
  }

  &.wtax-radio-group-row {
    column-gap: functions.spacing(4);
    display: inline-grid;
    grid-auto-flow: column;
  }
}

@use 'abstracts/variables';

@font-face {
  font-display: auto;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: map-get(variables.$font-weights, 'normal');
  src: url('^assets/fonts/manrope-regular.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: map-get(variables.$font-weights, 'bold');
  src: url('^assets/fonts/manrope-bold.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: map-get(variables.$font-weights, 'semi-bold');
  src: url('^assets/fonts/manrope-semi-bold.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: map-get(variables.$font-weights, 'extra-bold');
  src: url('^assets/fonts/manrope-extra-bold.woff2') format('woff2');
  src: url('^assets/fonts/Montserrat-Bold.woff2') format('woff2');
}

@use 'abstracts/variables';

.wtax-toggle {
  &.mat-disabled {
    .mat-slide-toggle-bar {
      background-color: map-get(variables.$wtax-blue-dark, 80);
    }
    .mat-slide-toggle-thumb {
      background-color: map-get(variables.$wtax-blue-dark, 40);
    }
  }

  .mat-slide-toggle-bar {
    background-color: map-get(variables.$wtax-blue-dark, 10);
  }
  .mat-slide-toggle-thumb {
    background-color: map-get(variables.$wtax-blue-dark, 03);
  }
}

@use 'abstracts/variables';
@use 'abstracts/functions';

.card-container {
  background-color: #fff;
  border-radius: functions.spacing(2);
  box-shadow:
    0 4px 30px 0 rgba(91, 98, 121, 0.14),
    0 -10px 20px 0 rgba(255, 255, 255, 0.4);
  padding: functions.spacing(3);

  &.dark {
    background-color: map-get(variables.$wtax-blue-dark, 03);
    box-shadow: none;
  }
}

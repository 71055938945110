@use 'abstracts/variables';

@each $config-name, $config in variables.$paragraphs {
  @each $font-weight-name, $font-weight in variables.$font-weights {
    .text-#{$config-name}-#{$font-weight-name} {
      font-size: map-get($config, 'font-size');
      font-weight: $font-weight;
      line-height: map-get($config, 'line-height');
    }
  }
}

@each $name, $value in variables.$font-weights {
  .font-weight-#{$name} {
    font-weight: $value;
  }
}

@each $name, $config in variables.$paragraphs {
  .font-size-#{$name} {
    font-size: map-get($config, 'font-size');
    line-height: map-get($config, 'line-height');
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@each $name, $config in variables.$headers {
  .#{$name} {
    font-size: map-get($config, 'font-size');
    font-weight: map-get(variables.$font-weights, 'extra-bold');
    line-height: map-get($config, 'line-height');
  }
}

@use 'abstracts/variables';

.wtax-checkbox {
  .mat-checkbox-layout {
    margin: 0;
  }

  .mat-checkbox-frame {
    background-color: map-get(variables.$wtax-white, 100);
    border-color: map-get(variables.$wtax-blue-dark, 20);
    border-width: 1px;
  }

  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 4px;
  }

  .mat-checkbox-inner-container {
    height: 24px;
    width: 24px;
  }

  .mat-checkbox-checkmark {
    height: 20px;
    margin: auto;
    width: 20px;
  }

  &.mat-checkbox-disabled {
    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: map-get(variables.$wtax-green, 40);
      }
    }

    &:not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        background-color: map-get(variables.$wtax-blue-dark, 10);
        border-color: map-get(variables.$wtax-blue-dark, 20);
      }
    }
  }

  &.custodian {
    &.mat-checkbox-checked.mat-primary {
      .mat-checkbox-background {
        background-color: map-get(variables.$wtax-custodian, 80);
      }
    }
  }

  &.private-wealth {
    &.mat-checkbox-checked.mat-primary {
      .mat-checkbox-background {
        background-color: map-get(variables.$wtax-private-wealth, 50);
      }
    }
  }
}

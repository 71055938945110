$base-spacing: 0.5rem;

$wtax-green: (
  100: #4dccb4,
  80: #71d6c3,
  60: #94e0d2,
  40: #b8ebe1,
  20: #d9f3ee,
  10: #edfaf7,
);

$wtax-green-secondary: (
  20: #e3f6e8,
  10: #f1fbf4,
);

$wtax-green-dark: (
  100: #2a7580,
);

$wtax-red: (
  100: #fb6a72,
  80: #fc888e,
  60: #fda6aa,
  20: #fee1e3,
  10: #fff0f1,
);

$wtax-orange: (
  60: #ffcb8e,
  20: #ffeed9,
  10: #fff6ec,
);

$wtax-orange-secondary: (
  100: #e59c1b,
);

$wtax-blue: (
  10: #edf8ff,
);

$wtax-black: (
  100: #0e1118,
  80: #3e4146,
  60: #6e7074,
);

$wtax-white: (
  100: #fff,
);

$wtax-blue-dark: (
  100: #5b6279,
  80: #7c8194,
  60: #9da1af,
  40: #c0c3cc,
  20: #dee0e4,
  10: #efeff2,
  03: #fafafb,
);

$wtax-custodian: (
  100: #3a5074,
  80: #5d82b2,
  60: #61738f,
  40: #cbd7e6,
  gray-primary: #a7a9ac,
  gray-secondary: #6d6e71,
);

$wtax-private-wealth: (
  100: #6b3d86,
  50: #ceb4dd,
  25: #efe6f4,
);

$wtax-state: (
  success: #72d28d,
  error: #fb6a72,
  warning: #ffa842,
  info: #4cbbfa,
  attention: #d60303,
);

$color-map: (
  green: $wtax-green,
  green-secondary: $wtax-green-secondary,
  green-dark: $wtax-green-dark,
  red: $wtax-red,
  orange: $wtax-orange,
  blue: $wtax-blue,
  black: $wtax-black,
  white: $wtax-white,
  blue-dark: $wtax-blue-dark,
  state: $wtax-state,
  custodian: $wtax-custodian,
  private-wealth: $wtax-private-wealth,
);

$font-sizes: (
  enormous: 40px,
  huge: 32px,
  big: 24px,
  increased: 20px,
  emphasized: 18px,
  regular: 16px,
  reduced: 14px,
  small: 12px,
  tiny: 10px,
);

$paragraphs: (
  enormous: (
    font-size: map-get($font-sizes, 'enormous'),
    line-height: 1.5,
  ),
  huge: (
    font-size: map-get($font-sizes, 'huge'),
    line-height: 1.25,
  ),
  big: (
    font-size: map-get($font-sizes, 'big'),
    line-height: 1.25,
  ),
  increased: (
    font-size: map-get($font-sizes, 'increased'),
    line-height: 1.2,
  ),
  emphasized: (
    font-size: map-get($font-sizes, 'emphasized'),
    line-height: 1.33,
  ),
  regular: (
    font-size: map-get($font-sizes, 'regular'),
    line-height: 1.5,
  ),
  reduced: (
    font-size: map-get($font-sizes, 'reduced'),
    line-height: 1.14,
  ),
  small: (
    font-size: map-get($font-sizes, 'small'),
    line-height: 1.33,
  ),
  tiny: (
    font-size: map-get($font-sizes, 'tiny'),
    line-height: 1.4,
  ),
);

$font-weights: (
  normal: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
);

$headers: (
  h1: (
    font-size: 32px,
    line-height: 1.25,
  ),
  h2: (
    font-size: 24px,
    line-height: 1.33,
  ),
  h3: (
    font-size: 20px,
    line-height: 1.2,
  ),
  h4: (
    font-size: 18px,
    line-height: 1.33,
  ),
);
